<template>
    <div class="products">
       <!--- Modals --->
        <itc-modal :showModal="showModal">
            <div slot="header">
                <h4>Añadir proveedor</h4>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-12">
                        <label>Nombre</label>
                        <input v-model="provider.name" type="text" class="form-control">
                    </div>
                    <div class="col-12 col-lg-6">
                        <label>Dirección</label>
                        <input v-model="provider.address" type="text" class="form-control">
                    </div>
                    <div class="col-12 col-lg-6">
                        <label>Teléfono</label>
                        <input v-model="provider.phone" type="number" class="form-control">
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="processProvider">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal = false">Cerrar</button>
                    </div>
            </div>
        </div>
        </itc-modal>
        <!--- / Modals --->
        <h4 class="pt-3">Proveedores<button @click="openModal('add')" class="mx-2 btn btn-success btn-sm"><i class="fas fa-plus"></i></button></h4>
        <hr>
        <div class="table-resposive my-3 custom-shadow">
            <table class="table text-center">
                <thead class="thead-dark">
                    <tr>
                        <th>Nombre</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(provider, i) in providers" :key="i" class="provider-item set-pointer"
                        @click="openModal('edit', provider)">
                        <td>{{provider.name}}</td>
                        <td>{{provider.address}}</td>
                        <td>{{provider.phone}}</td>
                    </tr>
                </tbody>
            </table>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

import * as utils from '@/assets/utils/utils.js'

import Modal from '@/components/mix/Modal'

export default {
    data(){
        return {
            showModal: false,
            providers: [],
            provider: {},
            toEdit: {}
        }
    },
    methods: {
        async  processProvider(){
            const findProvider = provider => provider._id == this.toEdit
            const index = this.providers.findIndex(findProvider)
            const alreadyExists = index != -1
            if(alreadyExists) this.providers.splice(index, 1)
            const resp = await axios.post(`/provider/${alreadyExists ? 'update' : 'add'}`, {
                providerId: alreadyExists ? this.toEdit : null,
                provider: JSON.stringify(this.provider),
            })
            var insertionIndex = utils.getInsertionIndex(this.providers, this.provider, utils.compareProviders)
            this.providers.splice(insertionIndex, 0, resp.data)
            this.showModal = false
        },
        openModal(action, provider){
            this.toEdit = provider?._id
            if(action == 'add')
                this.provider = {}
            else
                Object.assign(this.provider, provider)
            this.showModal = true
        }
    },
    components: {
        'itc-modal': Modal
    },
    async created(){
        const resp = await axios.get('/provider');
        this.providers = resp.data
    }   
}
</script>

<style lang="scss" scoped>
.add-fee{
    background-color: #343a40;
}
.provider-item:hover{
    background-color: lightgreen;
}
</style>